<template>
  <div class="/contacts">
    <Webgl24Bar/>
    <v-container fluid>
      <v-card d-inline-flex>
            <v-list>
              <v-list-item-group
              >
                <v-list-item href="tel:+79251887873"
                target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon id="icon_tel">mdi-phone-forward-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title id="tabb">+7(925)188-78-73</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://wa.me/79251887873"
                target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon id="icon_wats">mdi-whatsapp</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title id="tabb">WhatsApp</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://telegram.me/blend3dweb"
                target="_blank"
                >
                  <v-list-item-icon>
                    <img id="icon_telega" src="/img/telegram.svg">
                    <!-- <v-icon id="icon_telega"><img src="/main/icons/telegram.svg" alt="Telegram"></v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title id="tabb">Telegram</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item href="mailto:blend3dweb@gmail.com" target="_blank">
                  <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title id="tabb">blend3dweb@gmail.com</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://www.youtube.com/channel/UCoACg0TUd1wIwPg3FhTRT_A" target="_blank">
                  <v-list-item-icon>
                    <v-icon id="icon_youtube">mdi-youtube</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title id="tabb">YouTube Channel</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://drive.google.com/file/d/1tEDTxTZXFg6jpMsPquwJUztCwdlcIqaK/preview" target="_blank">
                  <v-list-item-icon>
                    <v-icon>mdi-file-search</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title id="tabb">recommendation</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
        </v-card>
      </v-container>
  </div>
</template>
<script>
import Webgl24Bar from '@/components/controls_vue/Webgl24Bar.vue';
export default {
  name: "Contacts",
  components: {
    Webgl24Bar,
  },
  data: () => ({
  }),
}
</script>

<style scoped>
.v-list-item-group {
  color: #42A5F5;

}
#tabb {
	/* position: absolute;
	padding: 20px; */
	/* color: #265787; */
  color: #78909C;
	opacity: 1;
	transition: opacity 0.6s;
	/* left: 0px;
	right: 0px;
	bottom: 25%; */
	font-family: "ToxicPowers";
  src: url("/preloader/ToxicPowers.ttf");
	/* text-align: center;
	font-size: 32px; */
}
#icon_tel {
  color: #FF5722;
	opacity: 1;
	transition: opacity 0.6s;
}
#icon_wats {
  color: #2E7D32;
	opacity: 1;
	transition: opacity 0.6s;
}
#icon_telega {
	opacity: 1;
	transition: opacity 0.6s;
  width: 50%;
  height: 50%;
}
#icon_youtube {
  color: #c4302b;
	opacity: 1;
	transition: opacity 0.6s;
}

@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
